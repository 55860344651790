<template>
  <div class="container has-text-centered">
    <section class="hero is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-6 is-offset-3">
              <h1 class="title">ຕິດຕາມເຄື່ອງ</h1>
              <div class="box">
                <div class="field is-grouped">
                  <div class="control is-expanded">
                    <input
                      v-model="code"
                      class="input is-large has-text-centered"
                      type="text"
                      placeholder="ປ້ອນເລກໃບບິນ"
                      @keyup.enter="check()"
                    />
                  </div>
                </div>
                <div>
                  <tracking-component :items="items" />
                </div>
              </div>
            </div>
          </div>
          <hr style="margin-top: 15em; margin-bottom: 5em" />
          <div class="columns">
            <div class="column">
              <b-icon icon="truck" size="is-large"></b-icon>
              <div class="title is-4">ການບໍລິການ</div>
              <hr />
              <div>
                <ul>
                  <li>ຂົນສົ່ງດ່ວນທັງພາຍໃນ ແລະ ຕ່າງປະເທດ</li>
                  <li>ບໍລິການໄວທັນໃຈ</li>
                  <li>ເປີດບໍລິການ 7 ວັນຕໍ່ອາທິດ</li>
                  <li>ເກັບເງິນປາຍທາງ (Cash on delivery)</li>
                </ul>
              </div>
            </div>
            <div class="column">
              <b-icon icon="package" size="is-large"></b-icon>
              <div class="title is-4">ຄວາມປອດໄພ</div>
              <hr />
              <div>
                <ul>
                   <li @click="policy"><a href="#" >ຂໍ້ກຳນົດ ແລະ ເງື່ອນໄຂການບໍລິການ</a></li>
                  <li>ຄວາມປອດໄພ</li>
                </ul>
              </div>
            </div>
            <div class="column">
              <b-icon icon="car" size="is-large"></b-icon>
              <div class="title is-4">ການຂົນສົ່ງສິນຄ້າ</div>
              <hr />
              <div>
                <ul>
                  <li>ຂົນສົ່ງດ່ວນທົ່ວປະເທດ</li>
                  <li>ບໍລິການວ່ອງໄວທັນໃຈ</li>
                  <li>ເປີດບໍລິການ 7 ວັນຕໍ່ອາທິດ</li>
                </ul>
              </div>
            </div>
          </div>
          <hr style="margin-top: 5em" />
          <div class="columns">
            <div class="column is-offset-3 is-6">
              <a
                href="http://play.google.com/store/?pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              >
                <img
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                />
              </a>

              <a
                href="https://apps.apple.com/us/app/bcel-one/id654946527?itsct=apps_box_badge&amp;itscg=30200"
                style="border-radius: 13px"
              >
                <img
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us"
                  alt="Download on the App Store"
                  style="border-radius: 13px; width: 90%"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import TrackingComponent from "../components/TrackingComponent.vue";
export default {
  components: { TrackingComponent },
  name: "Index",
  data() {
    return {
      code: "",
      items: null,
    };
  },
  mounted() {
    if (this.isAndroid) {
      this.$router.replace(`/login`);
    }
  },
  methods: {
    check() {
      if (this.code) {
        this.$router.push(`/track/${this.code}`);
      }
    },
     policy(){
        this.$router.push(`/policy`);
      }
  },
};
</script>
